import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import {getImage, GatsbyImage} from 'gatsby-plugin-image'
import { kebabCase } from 'lodash'
import { createLinkStyle } from '../../components/functions/createconst'
import * as styles from '../../styles/richinternallink.module.scss'

const RichInternalLink = ({slug}) =>{
    const LinkStyle = createLinkStyle()

    const query = useStaticQuery(
        graphql`
        query {
            allMdx {
                nodes {
                    slug
                    frontmatter {
                        category1
                        category2
                        title
                        hero {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                    }
                }
            }
        }`    
    )

    const nodes = query.allMdx.nodes
    let title
    let image
    let url

    for(let i = 0 ; i < nodes.length; i++){
        if(slug === nodes[i].slug){
            title = nodes[i].frontmatter.title
            image = nodes[i].frontmatter.hero
            const category1 = kebabCase(nodes[i].frontmatter.category1)
            const category2 = kebabCase(nodes[i].frontmatter.category2)
            url = `/${category1}/${category2}/${slug}`
            break
        }    
    }

    return(
        <a href={url} target = "_blank" rel="noopener norefferer" style={LinkStyle}>
            <div className = {styles.richinternallinkwrapper}>
                <div className={styles.flexwrapper}>
                    <GatsbyImage className = {styles.imagestyle}
                        alt = "thumbnailimg" 
                        image = {getImage(image)}
                        width = {200}
                    />
                    <div className={styles.title}>{title}</div>
                </div>
            </div>
        </a>

    )
}

export default RichInternalLink