import React from 'react'
import {StaticImage} from 'gatsby-plugin-image'
import * as styles from '../../styles/balloon.module.scss'

const Balloon = ({img, text}) =>{
    return(
        <div className = {styles.balloonwrapper}>
            <div className={styles.balloon}>
                <div className={styles.text}>
                    {text}
                </div>
            </div>
            <div className={styles.balloonimgwrapper}>
                {img ==="pale" ? 
                    <StaticImage
                        className={styles.balloonimg}
                        src = "../../images/pale.png"
                    />
                :
                img ==="good" ? 
                    <StaticImage
                        className={styles.balloonimg}
                        src = "../../images/good.png"
                    />
                :
                    <StaticImage
                        className={styles.balloonimg}
                        src = "../../images/normal.png"
                    />
                }
            </div>
        </div>
    )
}

export default Balloon