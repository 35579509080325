import React from 'react'
import {getImage, GatsbyImage} from 'gatsby-plugin-image'
import * as styles from '../../styles/captionimg.module.scss'

const CaptionImg = ({img, alt, size}) =>{
    return(
        <div className = {size==="S" ? styles.S : size==="M" ? styles.M : styles.L}>
            <GatsbyImage className = {styles.imagestyle}
                alt = {alt} 
                image = {getImage(img)}
                width = {300}
            />
            <div className={styles.caption}>
                {alt}
            </div>
        </div>
    )
}

export default CaptionImg