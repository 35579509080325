import React from "react"
import * as styles from '../../styles/moshimoaffiliate.module.scss'

const MoshimoAffiliate = ({ title, amazonurl, rakutenurl, yahoourl, imgurl}) => {
    let amazon = []
    let rakuten = []
    let yahoo = []
    let img = []

    const start = amazonurl.indexOf("dp%2F")
    const asin = amazonurl.substr(start+5, 10)

    if(amazonurl){
        amazon = <a 
                    href={`//af.moshimo.com/af/c/click?a_id=3406616&p_id=170&pc_id=185&pl_id=4062&url=${amazonurl}`}
                    target="_blank" 
                    rel="nofollow noopener noreferrer" 
                    referrerpolicy="no-referrer-when-downgrade">
                        <div className={styles.text}>
                            Amazonで見る
                        </div>
                    </a>
    }
    if(rakutenurl){
        rakuten = <a 
                    href={`//af.moshimo.com/af/c/click?a_id=3385497&p_id=54&pc_id=54&pl_id=616&url=${rakutenurl}`} 
                    target="_blank" 
                    rel="nofollow noopener noreferrer" 
                    referrerpolicy="no-referrer-when-downgrade">
                        <div className={styles.text}>
                            楽天で見る
                        </div>
                    </a>
    }
    if(yahoourl){
        yahoo = <a 
                    href={`//af.moshimo.com/af/c/click?a_id=3410507&p_id=1225&pc_id=1925&pl_id=18502&url=${yahoourl}`} 
                    target="_blank" 
                    rel="nofollow noopener noreferrer" 
                    referrerpolicy="no-referrer-when-downgrade">
                        <div className={styles.text}>
                            Yahoo!ショッピングで見る
                        </div>
                </a>
    }

    if(imgurl){
        img = 
        <img className={styles.img}
            src={imgurl}
            alt="商品イメージ" 
            loading="lazy"
        />
    }
    if(!imgurl){
        img = 
        <img className={styles.img}
            src={`https://images-fe.ssl-images-amazon.com/images/P/${asin}.09.MZZZZZZZ`}
            alt="商品イメージ" 
            loading="lazy"
        />
    }

    return (
        <div className={styles.affiliatewrapper}>
            <div className={styles.title}>
                {title}
            </div>

            <div className={styles.imgbuttonwrapper}>
                {img}

                <div className={styles.buttonswrapper}>
                    <div className={styles.amazonbuttonstyle}>
                        {amazon}
                    </div>
                    <div className={styles.rakutenbuttonstyle}>
                        {rakuten}
                    </div>
                    <div className={styles.yahoobuttonstyle}>
                        {yahoo}
                    </div>
                </div>

            </div>
        </div>
    )
}



export default MoshimoAffiliate