// extracted by mini-css-extract-plugin
export var flexwrapper = "linestamp-module--flexwrapper--pmgrU";
export var leadsubscribetext = "linestamp-module--leadsubscribetext--Ep1qR";
export var leadtext = "linestamp-module--leadtext--5jfPU";
export var lineadswrapper = "linestamp-module--lineadswrapper--IjMQJ";
export var linestampwrapper = "linestamp-module--linestampwrapper--k4LBo";
export var linkimg = "linestamp-module--linkimg--uwWvM";
export var linkwrapper = "linestamp-module--linkwrapper--1OIUm";
export var otomodachilinkwrapper = "linestamp-module--otomodachilinkwrapper--IS9Qb";
export var stamplinkwrapper = "linestamp-module--stamplinkwrapper--X+pbx";
export var subscribewrapper = "linestamp-module--subscribewrapper--3HEvg";