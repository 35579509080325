import React from 'react'
import {getImage, GatsbyImage} from 'gatsby-plugin-image'
import * as styles from '../../styles/singleimg.module.scss'

const SingleImg = ({img, alt, size}) =>{
    return(
        <div className = {size==="S" ? styles.S : size==="M" ? styles.M : styles.L}>
            <GatsbyImage className = {styles.imagestyle}
                alt = {alt} 
                image = {getImage(img)}
                width = {300}
            />
        </div>
    )
}

export default SingleImg